
import { Component, Vue, Prop } from "vue-property-decorator";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import { CategoryModel, CategoryRequestOptions } from "@/models/category";
import CategoryService from "@/services/category_service";
import AssetService from "@/services/asset_service";
import { APIResponse } from "@/models/api_res";
import { AuthError } from "@/services/error_service";
import { EventBus } from "@/events/index";
import { AssetModel, AssetRequestOptions } from "@/models/asset";
@Component({
  components: { Autocomplete }
})
export default class MoveItem extends Vue {
  @Prop({ default: false }) product!: boolean;
  @Prop({ default: false }) asset!: boolean;
  protected dataList: CategoryModel[] = [];
  protected categoryService = new CategoryService();
  protected assetService = new AssetService();
  protected updateField(value: any): void {
    this.$emit("setDestination", value);
  }
  protected reqNum = 0;

  protected autoCompleteFetch(value: any): void {
    this.reqNum++;
    const newReqNum = this.reqNum;
    if (this.product) {
      this.getCategories(newReqNum,{ q: value, leaf: true });
    } else if (this.asset) {
      this.getAssets({ q: value, path: "" });
    } else {
      this.getCategories(newReqNum,{ q: value });
    }
  }

  protected async getCategories(
    requestNumber: number,
    optionsObject?: CategoryRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.categoryService.getCategories(
        optionsObject
      );
      setTimeout(() => {
        if (requestNumber === this.reqNum) {
          const sortedNames = res.results.sort( (c1: CategoryModel, c2: CategoryModel) => {
            const name1 = c1.display_name.toUpperCase();
            const name2 = c2.display_name.toUpperCase();
            return (name1 < name2) ? -1 : (name1 > name2) ? 1 : 0;
          });
          const leafCats = sortedNames.filter( (category: CategoryModel) => {
            return category.is_leaf;
          });
          const nonLeafCats = sortedNames.filter( (category: CategoryModel) => {
            return !category.is_leaf;
          });
          if (this.product) {
            this.dataList = leafCats;
          } else {
            this.dataList = nonLeafCats;
          }
        }
      }, 100);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async getAssets(
    optionsObject?: AssetRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.assetService.getAssets(optionsObject);
      this.dataList = res.results.filter((item: AssetModel) => {
        return item.file_type === "dir";
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }
}
